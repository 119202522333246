<template>
  <div class="AgeGate">
    <div v-if="isOver18 === false && cookieSet" class="AgeGate__message">
      <div>
        <p>Husk at du skal være fyldt 18 år for at købe fyrværkeri.</p>
        <FButton @click="onAccept">Jeg er fyldt 18 år</FButton>
      </div>
    </div>
    <Modal ref="modal" dark>
      <div class="AgeGate__modalContent">
        <p>Husk at du skal være fyldt 18 år for at købe fyrværkeri.</p>
        <h2 class="AgeGate__modalTitle">Er du over 18 år?</h2>
        <div class="AgeGate__choices">
          <button class="AgeGate__choice" @click="onDecline">Nej</button>
          <button class="AgeGate__choice AgeGate__choice--yes" @click="onAccept">JA</button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import FButton from '../Button';
import { Modal } from '@components/Modal';
import Cookies from 'js-cookie';

export default {
  name: 'FireworksAgeGate',

  components: {
    Modal,
    FButton,
  },

  data() {
    return {
      isOver18: null,
      cookieSet: false,
      chooseListEl: null,
    };
  },

  watch: {
    isOver18(over18) {
      if (over18) {
        if (this.chooseListEl) {
          this.chooseListEl.classList.remove('is-disabled');
          this.enableLinks();
        }
      } else if (this.chooseListEl) {
        this.disableLinks();
        this.chooseListEl.classList.add('is-disabled');
      }
    },
  },

  created() {
    this.cookieSet = Cookies.get('over-18') !== undefined;
    this.isOver18 = Cookies.get('over-18') == 'true';
    const chooseListEls = document.getElementsByClassName('choose-list-action');
    if (chooseListEls.length) {
      this.chooseListEl = document.getElementsByClassName('choose-list-action')[0]; // check maybe needed
      this.linksEls = document.querySelectorAll('.choose-list-action a');
    }
  },

  mounted() {
    if (!this.cookieSet) {
      this.$refs.modal.open();
    }
  },

  methods: {
    onAccept() {
      Cookies.set('over-18', true);
      this.isOver18 = true;
      this.cookieSet = true;
      this.$refs.modal.close();
    },
    onDecline() {
      Cookies.set('over-18', false);
      this.cookieSet = true;
      this.isOver18 = false;
      this.$refs.modal.close();
    },
    preventDefault(event) {
      event.preventDefault();
    },
    disableLinks() {
      this.linksEls.forEach(item => {
        item.addEventListener('click', this.preventDefault);
      });
    },
    enableLinks() {
      this.linksEls.forEach(item => {
        item.removeEventListener('click', this.preventDefault);
      });
    },
  },
};
</script>

<style lang="scss" socped>
.AgeGate {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 30;
  .AgeGate__message {
    text-align: center;
    padding: 20px;
  }
  .AgeGate__modalTitle {
    font-size: 38px;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 30px;

    @include maxBreakpoint(600) {
      font-size: 28px;
    }
  }
  .AgeGate__modalContent {
    padding: 14px;
    text-align: center;
  }
  .AgeGate__choices {
    display: flex;
    gap: 20px;
  }
  .AgeGate__choice {
    display: block;
    flex: 1;
    text-align: center;
    padding: 14px;
    font-size: 16px;
    background: #021e39;
    color: white;
    outline: none;
    border: none;
  }
  .AgeGate__choice--yes {
    background: #5aab40;
    flex: 1.7;
    text-transform: uppercase;
    font-weight: bold;
  }
}
</style>

<style type="scss">
.choose-list-action.is-disabled {
  filter: blur(5px);
  opacity: 0.2;
  pointer-events: none;
}
</style>
