<template>
  <button class="FButton" @click="e => $emit('click', e)"><slot /></button>
</template>

<script>
export default {
  name: 'Button',
};
</script>

<style type="scss" scoped>
.FButton {
  display: block;
  padding: 15px 40px;
  border-radius: 30px;
  border: 1px solid #fff;
  display: inline-block;
  padding: 15px 40px;
  border-radius: 30px;
  font-size: 16px;
  font-family: Arial, sans-serif;
  background-color: transparent;
  outline: 0;
  cursor: pointer;
}
.FButton:hover {
  background: rgba(255, 255, 255, 0.1);
}
</style>
